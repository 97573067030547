"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Api {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    login(userData) {
        return this.httpClient.post("/auth/login", userData);
    }
    logout() {
        return this.httpClient.post("/auth/logout", {});
    }
    createUser(userData) {
        return this.httpClient.post("/users", userData);
    }
    createUserWithoutPass(userData) {
        return this.httpClient.post("/users", userData);
    }
    getUser() {
        return this.httpClient.get("/users/me");
    }
    updateUserField(data) {
        return this.httpClient.put("/users/me/", {
            field: data.field,
            value: data.value,
        });
    }
    getPlaylistsAnnounces() {
        return this.httpClient.get("/playlists/announces");
    }
    getPlaylist(id) {
        return this.httpClient.get(`/playlists/${id}`);
    }
    getPlaylistRatings(id) {
        return this.httpClient.get(`/ratings/${id}`);
    }
    setPlaylistRatings(data) {
        const { playlistId, videoId, value } = data;
        return this.httpClient.post(`/ratings/${playlistId}`, { videoId, value });
    }
    getCinemarket() {
        return this.httpClient.get(`/playlists/cinemarket`);
    }
    getPlaylistAddVideos(id) {
        return this.httpClient.get(`playlists/${id}/addVideos/`);
    }
    getRecommendedVideos(type) {
        return this.httpClient.get(`recommended-playlists/${type}`);
    }
    getSchoolAnnounces(filters = {}, pagParams) {
        return this.httpClient.get("/courses/announces", {
            params: { ...filters, ...pagParams },
        });
    }
    getUserCourses() {
        return this.httpClient.get("/courses/user-courses");
    }
    getCourseAboutPage(ltName) {
        return this.httpClient.get(`/courses/${ltName}/about`);
    }
    getCourseContentPage(ltName) {
        return this.httpClient.get(`/courses/${ltName}/content`);
    }
    getLessonPage(data) {
        return this.httpClient.get(`/courses/${data.courseId}/lessons/${data.lessonId}`);
    }
    getCourseHomework(courseId) {
        return this.httpClient.get(`/homeworks/${courseId}`);
    }
    sendHomework(data) {
        return this.httpClient.post("/homeworks", data);
    }
    getContestsAnnounces(filters = {}, pagParams) {
        return this.httpClient.get(`/contest/`, {
            params: { ...filters, ...pagParams },
            headers: {
                "Cache-Control": "no-cache",
            },
        });
    }
    getTopContests() {
        return this.httpClient.get(`/contest/top`);
    }
    getContest(name) {
        return this.httpClient.get(`/contest/${name}`);
    }
    getFavoritesContests() {
        return this.httpClient.get(`/favorite-contest/`);
    }
    setFavoriteContest(id) {
        return this.httpClient.post(`/favorite-contest/${id}`);
    }
    removeFavoriteContest(id) {
        return this.httpClient.delete(`/favorite-contest/${id}`);
    }
    getOrders() {
        return this.httpClient.get(`/orders/`);
    }
    uploadFile(file) {
        return this.httpClient.post("/upload/file", file);
    }
    getGoodsReviews() {
        return this.httpClient.get("/goods/reviews");
    }
    getScenarios() {
        return this.httpClient.get("/scenarios/");
    }
    uploadScenario(scenario) {
        return this.httpClient.post("/scenarios/", scenario);
    }
    getBroadcasts() {
        return this.httpClient.get("/broadcasts");
    }
    checkCoupon({ code, goodId }) {
        return this.httpClient.post(`/check-coupon/`, { code, goodId });
    }
    initOrder(data) {
        return this.httpClient.post("/orders/", data, {
            headers: { "X-Requested-With": "XMLHttpRequest" },
        });
    }
    sendForgetPasswordLink(email) {
        return this.httpClient.post("/forgot/send-link/", { email });
    }
    sendPasswordsLink(data) {
        return this.httpClient.post("/forgot/set-password", data);
    }
    sendConnectForm(data) {
        return this.httpClient.post(process.env.GATSBY_EMAIL_URL, null, {
            headers: {
                "Content-Type": "text/plain",
            },
            withCredentials: false,
            params: data,
        });
    }
    sendFirstLessonForm(data) {
        return this.httpClient.post("/send-first-link", data, {
            withCredentials: false,
        });
    }
    setReminder(type) {
        return this.httpClient.post("/reminders", { type });
    }
    getBaner() {
        return this.httpClient.get("/baner");
    }
}
exports.default = Api;
