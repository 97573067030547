import { applyMiddleware, combineReducers, createStore } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import ReduxThunk from "redux-thunk";
import crossTab from "./crossTab";
import Ratings from "./Rating";
import User from "./User";

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form"
  user: User,
  ratings: Ratings,
});

const persistedReducer = persistReducer(
  {
    key: "root",
    storage,
    blacklist: ["form", "ratings"],
  },
  reducer
);

export const store = createStore(persistedReducer, applyMiddleware(ReduxThunk));

const persistor = persistStore(store);
crossTab(persistor);

export default { store, persistor };
