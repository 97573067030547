import { Link, navigate } from "gatsby";
import * as React from "react";
import { connect } from "react-redux";
import { Default2 } from "src/components/querysComponents";
import StyledLink from "src/components/StyledLink";
import Typography from "src/components/Typography";
import accountLogo from "src/images/accountLogo.svg";
import logo from "src/images/potential-logo.svg";
import { actions as userActions } from "src/store/User";
import { User } from "src/types";
import styles from "./header.module.css";
import {links} from "./headerConst";
import MobileHeader from "./MobileHeader";

interface ConnectStateProps {
  user: User;
}

interface ConnectDispatchProps {
  logout(): typeof userActions.logout;
}

interface Props extends ConnectStateProps, ConnectDispatchProps {
  pagePath: string;
}

class Header extends React.Component<Props> {
  public render() {
    return (
      <Default2
        mobile={
          <MobileHeader
            isLogin={this.isLogin}
            renderAuthBlock={this.accountLogo}
            logout={this.props.logout}
            pagePath={this.props.pagePath}
          />
        }
      >
        {this.renderHeader()}
      </Default2>
    );
  }

  public renderHeader = () => {
    return (
      <nav className={`${styles.appHeaderWrapper} appWithContainer`}>
        <Link to="/" className={styles.appHeaderLogo}>
          <img src={logo} alt="logo potential" />
        </Link>
        <div className={styles.sections}>
          <StyledLink to={links.main}>Главная</StyledLink>
          <StyledLink to={links.scenario}>Рецензии</StyledLink>
          <StyledLink to={links.media}>Медиатека</StyledLink>
          <StyledLink to={links.school}>Школа</StyledLink>
          <StyledLink to={links.contests}>Конкурсы</StyledLink>
          <StyledLink to={links.ppm}>
            Potential Project Market
          </StyledLink>
          {this.isLogin() ? this.accountLogo() : this.loginButton()}
        </div>
      </nav>
    );
  };

  public isLogin = () => !!this.props.user && !!this.props.user.email;

  private loginButton() {
    return (
      <Link
        to="/login"
        state={{
          modal: true,
          noScroll: true,
          closeTo: this.props.pagePath,
        }}
        className={styles.loginButton}
      >
        <Typography type="biggerText">ВХОД</Typography>
      </Link>
    );
  }

  private accountLogo = () => {
    return (
      <Link to="/account/orders">
        <img src={accountLogo} alt="переход в личный кабинет" />
      </Link>
    );
  };
}

const mapStateToProps = (state) => ({
  // tslint:disable-next-line
  user: state.user.user,
});

const mapActionsToProps = {
  logout: userActions.logout,
};

export default connect<ConnectStateProps, ConnectDispatchProps>(
  mapStateToProps,
  // @ts-ignore
  mapActionsToProps
)(Header);
