import React from "react";
import { connect } from "react-redux";
import CoreLayout from "src/components/CoreLayout";
import Footer from "src/components/Footer";
import Header from "src/components/Header";
// tslint:disable-next-line: no-import-side-effect
import "src/modalStyles.css";
import { actions as userActions } from "src/store/User";
import { PageProps, User } from "src/types";
import layoutModuleCss from "./layout.module.css";

interface ConnectStateProps {
  user: User;
}
interface ConnectDispatchProps {
  getUser(): typeof userActions.getUser;
}

interface Props extends ConnectStateProps, ConnectDispatchProps, PageProps {
  children: Node;
}

class App extends React.Component<Props> {
  public timer?: number;

  public componentDidMount() {
    if (!this.props.user || !this.props.user.email) {
      return;
    }

    this.props.getUser();
    this.timer = window.setInterval(() => {
      this.props.getUser();
    }, 1000 * 60);
  }

  public componentDidUpdate() {
    if (!this.timer && this.props.user && this.props.user.email) {
      this.timer = window.setInterval(() => {
        this.props.getUser();
      }, 1000 * 60);
    }
  }

  public componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  public render() {
    return (
      <div className={layoutModuleCss.appContainer}>
        <Header pagePath={this.props.location.pathname} />
        <CoreLayout {...this.props}>{this.props.children}</CoreLayout>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // tslint:disable no-unsafe-any
  user: state.user.user,
  // tslint:enable no-unsafe-any
});

const mapActionsToProps = {
  getUser: userActions.getUser,
};

export default connect<ConnectStateProps, ConnectDispatchProps>(
  mapStateToProps,
  // @ts-ignore
  mapActionsToProps
)(App);
