export { default as wrapRootElement } from "./src/store/ReduxWrapper";

export { default as wrapPageElement } from "./src/components/Layout/LayoutPageWrapper";

export const shouldUpdateScroll = (data) => {
  if(!data || !data.routerProps || !data.routerProps.location) return
  return data.routerProps.location.hash;
};

export const onRouteUpdate = ({ location }) => {
  if (!location.hash) {
    return;
  }
  const elem = document.getElementById(location.hash);
  if (!elem) return;
  elem.scrollIntoView({
    behavior: "smooth",
  });
};
