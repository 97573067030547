import * as React from "react";
// tslint:disable-next-line: no-import-side-effect
import "src/indexStyles.css";
import typographyModuleCss from "./typography.module.css";

interface Props extends React.HTMLAttributes<HTMLSpanElement> {
  type:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "text"
    | "boldText"
    | "biggerText"
    | "smallerText";
  children: React.ReactNode;
  black?: boolean;
  className?: string;
}

class Typography extends React.Component<Props> {
  public getClassName = () => {
    const styles = [
      typographyModuleCss[this.props.type],
      this.props.className ? this.props.className : "",
    ];
    if (this.props.black) {
      styles.push(typographyModuleCss.black);
    }
    return styles.join(" ");
  };

  public render() {
    return (
      <span {...this.props} className={this.getClassName()}>
        {this.props.children}
      </span>
    );
  }
}

export default Typography;
