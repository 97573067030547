import { LocationContext } from "@reach/router";
import * as React from "react";
import Layout from "./layout";

const wrapPageElement = ({
  element,
  props
}: {
  element: React.ElementType;
  props: LocationContext;
}) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <Layout {...props}>{element}</Layout>;
};

export default wrapPageElement;
