import Api from "src/api";
import { VideoRating } from "src/types";
import { Action, Dispatch } from "./storeTypes";

const ratingConstants = {
  DATA_FETCHING: "USERS_FETCHING",
  DATA_FETCHED: "USERS_FETCHED",
  DATA_FETCH_FAILED: "USERS_FETCH_FAILED",
  ALL_DATA_FETCHED: "ALL_DATA_FETCHED",
};

export type FetchConstants = {
  DATA_FETCHING: string;
  DATA_FETCHED: string;
  DATA_FETCH_FAILED: string;
  GET_USER: string;
};

type VideoRatingMap = {
  [x: string]: VideoRating;
};

export const actions = {
  setRating: (data: { playlistId: string; videoId: string; value: number }) => {
    return async (dispatch: Dispatch<VideoRatingMap>) => {
      dispatch({
        type: ratingConstants.DATA_FETCHING,
      });
      try {
        const res = await Api.setPlaylistRatings({
          playlistId: data.playlistId,
          videoId: data.videoId,
          value: data.value,
        });

        dispatch({
          type: ratingConstants.DATA_FETCHED,
          payload: { [res.data.videoId]: res.data },
        });
      } catch (e) {
        dispatch({
          type: ratingConstants.DATA_FETCH_FAILED,
        });
      }
    };
  },
  getRatings: (playlistId: string) => {
    return async (dispatch: Dispatch<{ [x: string]: VideoRating }>) => {
      dispatch({
        type: ratingConstants.DATA_FETCHING,
      });
      try {
        const res = await Api.getPlaylistRatings(playlistId);

        const objData = res.data.reduce(arrToObject, {});

        dispatch({
          type: ratingConstants.ALL_DATA_FETCHED,
          payload: objData,
        });
      } catch (e) {
        dispatch({
          type: ratingConstants.DATA_FETCH_FAILED,
        });
      }
    };
  },
};

// Reducer
export const defaultState: RatingState = {
  fetching: false,
  ratings: {},
};

export type RatingState = {
  ratings: VideoRatingMap;
  fetching: boolean;
};

export default function(
  state: RatingState = defaultState,
  action: Action<VideoRatingMap>
): RatingState {
  switch (action.type) {
    case ratingConstants.DATA_FETCHING:
      return { ...state, fetching: true };
    case ratingConstants.ALL_DATA_FETCHED:
      return {
        ...state,
        fetching: false,
        ratings: { ...state.ratings, ...action.payload },
      };
    case ratingConstants.DATA_FETCHED:
      return {
        ...state,
        fetching: false,
        ratings: { ...state.ratings, ...action.payload },
      };
    case ratingConstants.DATA_FETCH_FAILED:
      return { ...state, fetching: false };
    default:
      return state;
  }
}

const arrToObject = (result, item: VideoRating) => ({
  ...result,
  [item.videoId]: item,
});
