import { Link } from "gatsby";
import * as React from "react";
import Expand from "react-expand-animated";
import ReactOutsideClickHandler from "react-outside-click-handler";
import {links} from "src/components/Header/headerConst";
import Typography from "src/components/Typography";
import menuIcon from "src/images/menuIcon.svg";
import logo from "src/images/smallLogo.svg";
import { actions as userActions } from "src/store/User";
import headerModuleCss from "./header.module.css";
import styles from "./mobileHeader.module.css";

interface Props {
  pagePath: string;
  isLogin(): boolean;
  renderAuthBlock(): React.ReactNode;
  logout(): typeof userActions.logout;
}

interface State {
  isOpen: boolean;
}

class MobileHeader extends React.Component<Props, State> {
  public state = {
    isOpen: false,
  };

  public render() {
    return (
      <ReactOutsideClickHandler onOutsideClick={this.handleOutsideClick}>
        <div
          className={[styles.mobileHeaderContainer, "appWithContainer"].join(
            " "
          )}
        >
          {this.renderMenuIcon()}
          {this.renderLogo()}
          {this.props.renderAuthBlock()}
        </div>
        {this.renderExpandHeader()}
      </ReactOutsideClickHandler>
    );
  }

  public toggleHeader = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  public renderExpandHeader = () => {
    return (
      <Expand open={this.state.isOpen} className={styles.expanderWrapper}>
        <div className={styles.linksWrapper}>
          {this.whiteLink(links.main, "ГЛАВНАЯ")}
          {this.whiteLink(links.scenario, "РЕЦЕНЗИИ")}
          {this.whiteLink(links.contests, "Конкурсы")}
          {this.whiteLink(links.media, "МЕДИАТЕКА")}
          {this.whiteLink(links.school, "Школа")}
          {this.whiteLink(
            links.ppm,
            "Potential Project Market"
          )}
          <div className={styles.separator} />
          {this.whiteLink("/account/user", "Профиль")}
          {this.whiteLink("/account/orders", "Оплачено")}
          {this.whiteLink("/account/reviews", "Рецензирование")}
          {this.whiteLink("/account/courses", "Курсы")}
          {this.props.isLogin() ? (
            <div onClick={this.handleLogOut} role="button">
              <Typography type="biggerText">ВЫХОД</Typography>
            </div>
          ) : (
            <Link
              to="/login"
              state={{ modal: true, closeTo: this.props.pagePath }}
            >
              <Typography type="biggerText">ВХОД</Typography>
            </Link>
          )}
        </div>
      </Expand>
    );
  };

  public renderLogo = () => {
    return (
      <div className={headerModuleCss.appHeaderLogo}>
        <a href="https://potential-platform.com/" target="blank">
          <img src={logo} alt="logo potential" />
        </a>
      </div>
    );
  };

  public handleOutsideClick = () => {
    if (this.state.isOpen) {
      this.toggleHeader();
    }
  };

  private handleLogOut = () => {
    this.props.logout();
  };

  private whiteLink(to: string, txt: string) {
    return (
      <Link to={to} onClick={this.toggleHeader}>
        <Typography type="biggerText">{txt}</Typography>
      </Link>
    );
  }

  private renderMenuIcon() {
    return (
      <div
        className={headerModuleCss.menuIconWrapper}
        role="button"
        onClick={this.toggleHeader}
      >
        <img src={menuIcon} alt="меню" />
      </div>
    );
  }
}

export default MobileHeader;
