import { navigate } from "gatsby";
import * as React from "react";
import { connect } from "react-redux";
import StyledLink from "src/components/StyledLink";
import Typography from "src/components/Typography";
import { actions as userActions } from "src/store/User";
import { User } from "src/types";
import styles from "./AccountLayout.module.css";

interface Props extends ConnectStateProps, ConnectDispatchProps {
  className?: string;
  children: React.ReactNode;
}

class AccountLayout extends React.Component<Props> {
  public async componentDidMount() {
    if (!this.isUserLogin()) {
      await navigate("/register");
      return;
    }
  }

  public async componentDidUpdate() {
    if (!this.isUserLogin()) {
      await navigate("/register");
      return;
    }
  }

  public isUserLogin = () => {
    return !(!this.props.user || !this.props.user.email);
  };

  public render() {
    return (
      <main className={`${this.props.className} ${styles.accountWrapper}`}>
        <div className={styles.colorBlock} />
        <div className={`${styles.accountLayout} appWithContainer`}>
          <div className={styles.sideBar}>
            <div className={styles.navigation}>
              <StyledLink to="/account/user">ПРОФИЛЬ</StyledLink>
              <StyledLink to="/account/orders">ОПЛАЧЕНО</StyledLink>
              <StyledLink to="/account/reviews">РЕЦЕНЗИРОВАНИЕ</StyledLink>
              <StyledLink to="/account/contests">конкурсы</StyledLink>
              <StyledLink to="/account/courses">школа</StyledLink>
            </div>
            <div
              className={styles.logoutButton}
              onClick={this.handleLogout}
              role="button"
            >
              <Typography type="biggerText">Выход</Typography>
            </div>
          </div>
          <div className={styles.children}>{this.props.children}</div>
        </div>
      </main>
    );
  }

  private handleLogout = async () => {
    this.props.logout();
    await navigate("/");
  };
}

interface ConnectStateProps {
  user: User;
}

interface ConnectDispatchProps {
  logout(): typeof userActions.logout;
}

const mapStateToProps = (state) => ({
  // tslint:disable no-unsafe-any
  user: state.user.user,
  // tslint:enable no-unsafe-any
});

const mapActionsToProps = {
  logout: userActions.logout,
};

export default connect<ConnectStateProps, ConnectDispatchProps>(
  mapStateToProps,
  // @ts-ignore
  mapActionsToProps
)(AccountLayout);
