import * as React from "react";
import Typography from "src/components/Typography";
import buttonModuleCss from "./button.module.css";

interface Props {
  type?: "button" | "submit" | "reset";
  children: React.ReactNode;
  disabled?: boolean;
  black?: boolean;
  style?: object;
  id?: string;
  className?: string;
  handleClick?(): void | Promise<void>;
}

class Button extends React.Component<Props> {
  public getClassName = () => {
    const styles = [buttonModuleCss.button, this.props.className];
    if (this.props.black) {
      styles.push(buttonModuleCss.black);
    }
    if (this.props.disabled) {
      styles.push(buttonModuleCss.disabled);
    }
    return styles.join(" ");
  };

  public render() {
    return (
      <button
        style={this.props.style}
        className={this.getClassName()}
        type={this.props.type || "button"}
        onClick={this.props.handleClick}
        disabled={this.props.disabled}
        id={this.props.id}
      >
        <div className={buttonModuleCss.buttonText}>
          <Typography type="boldText" black={this.props.black}>
            {this.props.children}
          </Typography>
        </div>
      </button>
    );
  }
}

export default Button;
