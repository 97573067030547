import { Link } from "gatsby";
import * as React from "react";
import Typography from "src/components/Typography";

type Props = { to: string };

const StyledLink: React.FunctionComponent<Props> = (props) => (
  <Link
    activeStyle={{
      borderBottom: "2px solid white",
      textTransform: "uppercase",
    }}
    to={props.to}
  >
    <Typography type="biggerText">{props.children}</Typography>
  </Link>
);

export default StyledLink;
