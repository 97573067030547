import Api from "src/api";
import { User } from "src/types";
import { Action, Dispatch } from "./storeTypes";

const userConstants = {
  LOGIN_COMPLETE: "LOGIN_FETCHED",
  LOGOUT: "LOGOUT",
  LOGIN_FETCHING: "LOGIN_FETCHING",
  LOGIN_FAILED: "LOGIN_FAILED",
  DATA_FETCHING: "USERS_FETCHING",
  DATA_FETCHED: "USERS_FETCHED",
  DATA_FETCH_FAILED: "USERS_FETCH_FAILED",
  GET_CURR_USER: "USERS_GET_CURR_USER",
  UPDATE_USER: "USERS_UPDATE_USER",
};

export type FetchConstants = {
  DATA_FETCHING: string;
  DATA_FETCHED: string;
  DATA_FETCH_FAILED: string;
  GET_USER: string;
};

export const actions = {
  completeLogIn: (payload: User) => {
    return {
      type: userConstants.LOGIN_COMPLETE,
      payload,
    };
  },
  sendUser: ({
    email,
    password,
    name,
    lastName,
  }: {
    email: string;
    password: string;
    name: string;
    lastName: string;
  }) => {
    return async (dispatch: Dispatch<User>) => {
      dispatch({ type: userConstants.LOGIN_FETCHING });
      try {
        await Api.createUser({ email, password, name, lastName });
        // tslint:disable-next-line: no-unsafe-any
        await dispatch(actions.login(email, password));
      } catch (e) {
        dispatch({
          type: userConstants.LOGIN_FAILED,
        });
      }
    };
  },
  createUserWithoutPassword: ({
    email,
    name,
    lastName,
  }: {
    email: string;
    name: string;
    lastName: string;
  }) => {
    return async (dispatch: Dispatch<User>) => {
      dispatch({ type: userConstants.LOGIN_FETCHING });
      let isLoginSuccess = false;
      try {
        const res = await Api.createUserWithoutPass({
          email,
          name,
          lastName,
        });
        // tslint:disable-next-line: no-unsafe-any
        await dispatch(actions.login(email, res.data.onetimePass));
        isLoginSuccess = true;
      } catch (e) {
        dispatch({
          type: userConstants.LOGIN_FAILED,
        });
        isLoginSuccess = false;
      }
      return isLoginSuccess;
    };
  },
  getUser: () => {
    return async (dispatch: Dispatch<User>) => {
      let isLoginSuccess = false;
      dispatch({ type: userConstants.GET_CURR_USER });
      try {
        const response = await Api.getUser();
        dispatch(actions.completeLogIn(response.data));
        isLoginSuccess = true;
      } catch (e) {
        console.error("Error: ", e);
        return isLoginSuccess;
      }
    };
  },
  login: (email: string, password: string, token?: string) => {
    return async (dispatch: Dispatch<User>) => {
      dispatch({ type: userConstants.LOGIN_FETCHING });
      let isLoginSuccess = false;
      try {
        const res = await Api.login({ email, password, token });
        dispatch(actions.completeLogIn(res.data));
        isLoginSuccess = true;
      } catch (e) {
        dispatch({
          type: userConstants.LOGIN_FAILED,
        });
        isLoginSuccess = false;
      }
      return isLoginSuccess;
    };
  },
  logout: () => {
    return async (dispatch: Dispatch<User>) => {
      try {
        await Api.logout();
        dispatch({ type: userConstants.LOGOUT });
      } catch (e) {
        console.error(e);
      }
    };
  },
};

// Reducer
export const defaultUserState: UserState = {
  fetching: false,
  user: undefined,
};

export type UserState = {
  user?: User;
  fetching: boolean;
};

export default function(
  state: UserState = defaultUserState,
  action: Action<User>
): UserState {
  switch (action.type) {
    case userConstants.LOGIN_FETCHING:
      return { ...state, fetching: true, user: null };
    case userConstants.LOGIN_COMPLETE:
      return {
        ...state,
        fetching: false,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case userConstants.LOGIN_FAILED:
      return { ...state, fetching: false, user: undefined };
    case userConstants.LOGOUT:
      return { ...state, user: null };
    default:
      return state;
  }
}
