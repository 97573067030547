import * as React from "react";
import AccountLayout from "src/routes/AccountLayout";
import { PageProps } from "src/types";
import coreLayoutModuleCss from "./coreLayout.module.css";

interface Props extends PageProps {
  children: React.ReactNode;
}

class CoreLayout extends React.Component<Props> {
  public cleanRoutes: string[] = [
    "/forget-password/",
    "/pay/",
    "/payment/success/",
    "/reset-password/",
    "/auth-fail/",
  ];

  public render() {
    if (this.props.uri.includes("/account")) {
      return (
        <AccountLayout className={coreLayoutModuleCss.coreLayout}>
          {this.props.children}
        </AccountLayout>
      );
    }

    if (this.cleanRoutes.includes(this.props.location.pathname)) {
      return this.props.children;
    }

    return (
      <main
        className={`${coreLayoutModuleCss.mainLayout} ${coreLayoutModuleCss.coreLayout} appWithContainer`}
      >
        {this.props.children}
      </main>
    );
  }
}

export default CoreLayout;
